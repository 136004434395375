.shop-list
{
  color: #555555;
  box-sizing: border-box;
  height: 100%;
  overflow: scroll;

  .shop
  {
    border-bottom: 1px solid #EEEEEE;
    padding: 16px;
    font-size: 12px;
    position: relative;
  }

  tr
  {
    background-color: transparent !important;
  }

  .shop:nth-child(even)
  {
    background-color: #f7f7f7;
  }
}
