.share
{
  ul
  {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li
    {
      display: inline;
      margin: 4px;
    }
  }
}
