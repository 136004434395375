.shop-meta-table
{
  border-collapse: collapse;
  width: 100%;

  th, td
  {
    font-size: 0.8rem;
    text-align: left;
    padding: 4px;
    border-bottom: 1px solid #eeeeee;
  }

  th
  {
    white-space: nowrap;
    vertical-align: top;
  }

  tr:nth-last-child(even)
  {
    background-color: #f5f5f5;
  }
}
