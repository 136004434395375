*
{
  padding: 0;
  margin: 0;
  font-family: 'Hiragino Kaku Gothic Pro','ヒラギノ角ゴ Pro W3','メイリオ',Meiryo,'ＭＳ Ｐゴシック',sans-serif;
  font-size: 16px;
}

.small
{
  font-size: 0.8rem;
}

.nowrap
{
  white-space: nowrap;
  margin-right: 8px;
}

img
{
  max-width: 100%;
  height: auto;
}

html, body, #root
{
  width: 100%;
  height: 100%;
}

body
{
  position: fixed;
  overflow: hidden;
}

#root
{
  background-image: url('./background.jpg');
  background-size: cover;
}

.branding
{
  margin-bottom: 40px;
  text-align: center;
  .logo
  {
    font-family: "Nico Moji";
    font-size: 72px;
  }
  .link
  {
    font-size: 1.2rem;
  }
  .image
  {
    width: 96px;
    height: 96px;
    display: block;
    margin: auto;
  }
}
