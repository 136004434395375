.shop-link
{
  display: block;
  padding: 0;
  color: #555555;
  padding-right: 32px;
  text-align: left;
  top: 0;
  left: 0;
  background-color: transparent;
  border: none;
  width: 100%;

  .right
  {
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  h2
  {
    font-size: 1.4rem;
    margin-bottom: 0;
  }

  .category,
  .distance
  {
    border-radius: 4px;
    background-color: #41b0f5;
    color: #ffffff;
    padding: 4px;
    font-size: 0.8rem;
  }

  .category
  {
    background-color: #f5b041;
  }
}
